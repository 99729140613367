#klaro {
	.cookie-notice {
		font-family: 'TiemposText-Regular', serif;
		background-color: #ffffff;
		color: #1A262E;

		a {
			color: #54BAB3 !important;
		}

		.cn-body {

			span {
				font-size: 1rem;
				color: #1A262E;
			}

			.cm-link {
				color: #54BAB3;
			}

			.cm-btn {
				border-radius: 0;
				color: #ffffff;
				font-family: arboria, sans-serif;
				font-weight: 500;
				font-style: normal;
				text-transform: uppercase;
				border: 2px solid #54BAB3;
				background-color: #54BAB3;
				letter-spacing: 0.64px;

				&:hover {
					color: #fff;
					background-color: #1A262E;
					border: 2px solid #1A262E;
				}
			}

		}
	}

	.cm-modal {
		font-family: 'TiemposText-Regular', serif;
		background-color: #ffffff;
		color: #1A262E;

		span {
			color: #1A262E;
			a {
				color: #54BAB3;
			}
		}

		.slider {
			background-color: #54BAB3;
		}

		.cm-btn {
			border-radius: 0;
			color: #ffffff;
			font-family: arboria, sans-serif;
			font-weight: 500;
			font-style: normal;
			text-transform: uppercase;
			border: 2px solid #54BAB3;
			background-color: #54BAB3;
			letter-spacing: 0.64px;

			&:hover {
				color: #fff;
				background-color: #1A262E;
				border: 2px solid #1A262E;
			}
		}
	}

	.cookie-modal {
		a {
			color: #54BAB3 !important;
		}

		.hide {

			svg {
				stroke: #1A262E;
			}

		}
	}
}
