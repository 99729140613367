// Bootstrap
@import 'components/cookies';
@import 'components/bootstrap';

/* Custom fonts */
//@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'TiemposHeadline';
  src: url('/fonts/subset-TiemposHeadline-Medium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/subset-TiemposHeadline-Medium.ttf'),
  url('/fonts/subset-TiemposHeadline-Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'TiemposText-Medium';
  src: url('/fonts/subset-TiemposText-Medium.eot'); /* IE9 Compat Modes */
  src: url('/fonts/subset-TiemposText-Medium.ttf'),
  url('/fonts/subset-TiemposText-Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'TiemposText-Regular';
  src: url('/fonts/subset-TiemposText-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/subset-TiemposText-Regular.ttf'),
  url('/fonts/subset-TiemposText-Regular.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'TiemposText-RegularItalic';
  src: url('/fonts/subset-TiemposText-RegularItalic.eot'); /* IE9 Compat Modes */
  src: url('/fonts/subset-TiemposText-RegularItalic.ttf'),
  url('/fonts/subset-TiemposText-RegularItalic.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'TiemposText-Semibold';
  //src: url('../fonts/subset-TiemposText-Semibold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/subset-TiemposText-Semibold.ttf'),
  url('/fonts/subset-TiemposText-Semibold.woff');
  font-weight: 600;
}

/* ------------------------------------------------------------------------------------------ */
/* General styles & overrides */

body {
  font-family: 'TiemposText-Regular', serif;
  background-color: #ffffff;
  color: #40434D;
  margin: 0;
  padding: 0;
}

a {
  color: #54BAB3;
}

a:hover {
  color: #1A262E;
}

.btn {
  border-radius: 0;
}

.btn-primary {
  color: #ffffff;
  font-family: arboria, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  border: 2px solid #54BAB3;
  background-color: #54BAB3;
  letter-spacing: 0.64px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1A262E;
  border: 2px solid #1A262E;
}

.btn-primary:disabled {
	background-color: #aaa;
	border-color: #aaa;
}

.btn-lg {
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-link {
  color: #54BAB3;
  font-family: arboria, sans-serif;
  font-weight: 500;
  font-size: 1.05rem;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0;
  letter-spacing: 1px;
}

.btn-link:hover {
  color: #1A262E;
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #54BAB3;
  border-color: #54BAB3;
}

btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #54BAB3;
  border-color: #54BAB3;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 50%);
}


/* ------------------------------------------------------------------------------------------ */
/* Text styles */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  line-height: 1.24;
  font-family: 'TiemposHeadline', serif;
  color: #1A262E;
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'TiemposHeadline', serif;
  font-size: 3rem;
}

h3 {
  font-family: 'TiemposHeadline', serif;
  font-size: 2.5rem;
}

h4 {
  font-family: 'TiemposHeadline', serif;
  font-size: 2rem;
}

h5 {
  font-family: 'TiemposHeadline', serif;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

h6 {
  font-family: 'TiemposHeadline', serif;
  font-size: 1rem;
  margin-bottom: 1.2rem;
}

p {
  font-family: 'TiemposText-Regular', sans-serif;
  font-size: 1.25rem;
  color: #40434D;
}

p.small {
  font-family: 'TiemposText-Regular', sans-serif;
  font-size: 1rem;
  color: #40434D;
}

.dark {
  color: #1A262E;
  margin-bottom: 2.5rem;
}

/* ------------------------------------------------------------------------------------------ */
/* Navigation overrides */

.bg-light {
  background-color: transparent!important;
}

.navbar {
  padding: .5rem 0;
}

/* ------------------------------------------------------------------------------------------ */
/* Additional CSS box styles */

.pl-6 {
  padding-left: 6rem;
}

.pt-8 {
  padding-top: 6rem;
}

.pt-6 {
  padding-top: 4rem;
}

.mb-8 {
  margin-bottom: 6rem;
}

.mt--8 {
  margin-top: -2rem;
}

/* ------------------------------------------------------------------------------------------ */
/* Let's go! */

.hero span.upper {
  font-size: 2rem;
  color: #54BAB3;
  padding-left: 6rem;
  margin-bottom: 0rem;
  display: block;
}

.hero {
  min-height: 800px;
}

.hero p {
  line-height: 2rem;
  margin-bottom: 3rem;
}

small {
  color: #6D6E74;
  font-size: 0.88rem;
}

hr {
  margin-top: 5rem;
  margin-bottom: 4rem;
  border: 0;
  border-top: 2px solid #EAEFF5;
}

.card {
  background-color: #eaeff5;
  border: 0;
  border-radius: 0;
}

.card-body {
  padding: 2rem 2rem 1.5rem 2rem;
}

p.card-text {
  font-size: 1rem;
}

/* ------------------------------------------------------------------------------------------ */
/* Forms */

form {
  display: block;
  margin-top: 0em;
  padding: 4rem 6rem;
  background-color: #EAEFF5;
  font-family: arboria, sans-serif;
  font-weight: 500;
  font-style: normal;
}

form p {
  font-size: 1rem;
  color: #40434D;
  margin-bottom: 1.5rem!important;
  line-height: 1.75rem!important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2em + 1.5rem);
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.form-group {
  margin-bottom: 1.32rem;
}

.form-group label {
  display: inline-block;
  margin-bottom: .25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-group input {
  font-weight: 400;
}

.form-group input:focus, textarea:focus {
  border-color: #000;
}

.form-check {
  margin-bottom: 0rem;
}

.form-check-label {
  text-transform: none!important;
  letter-spacing: 0!important;
  font-weight: 500;
}

.end-of-universe {
  z-index: -999;
  position: relative;
}

.go-up {
  margin-top: -464px;
  z-index: 999;
  position: relative;
}

/* ------------------------------------------------------------------------------------------ */
/* Calculator */

.hero-content {
  padding: 3rem 4rem 4rem 1rem;
  background-color: #ffffff;
  margin-top: -280px;
}

/* ------------------------------------------------------------------------------------------ */
/* Call Me CTA */

.call-me {
  margin-top: -280px;
}

.atmo-img {
  position: relative;
  left: 5rem;
  z-index: 100;
}

.form-back {
  background-color: #1A262E;
  position: relative;
  margin-top: 8rem;
  padding: 10rem;
}

.form-back h1,h4 {
  color: #ffffff;
  margin-bottom: 4rem;
}

.form-back form {
  padding: 0;
  background-color: #1A262E;
}

.form-back .form-group label {
  color: #ffffff;
}

.call-me .btn-primary:hover {
  border: 2px solid #ffffff;
}

/* ------------------------------------------------------------------------------------------ */
/* Footer */

footer {
  padding: 0 0 4rem;
}

a.social-icon {
  text-align: center;
  margin: 0 0.64rem 0 0;
}

/* ------------------------------------------------------------------------------------------ */
/* Media Queries */

@media (min-width: 1200px) {

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1304px;
  }

}

@media (min-width: 992px) and (max-width: 1199.98px) {

  .go-up {
    margin-top: 48px;
    z-index: 999;
    position: relative;
  }

  .end-of-universe img {
    margin-top: -32px;
  }


}

@media (min-width: 768px) and (max-width: 991.98px) {

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 90%;
  }


  .go-up {
    margin-top: 48px;
    z-index: 999;
    position: relative;
  }

  .end-of-universe img {
    margin-top: -32px;
  }

  hr {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  .hero-content {
    padding: 2rem 1rem 3rem 1rem;
    background-color: #ffffff;
    margin-top: 0px;
  }

  .mt--8 {
    margin-top: 0;
  }

  .hero p {
    line-height: 2rem;
    margin-bottom: 0;
  }

  .atmo-img {
    position: relative;
    left: 0rem;
    z-index: 100;
  }

  .form-back {
    margin-top: 0rem;
    padding: 4rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 90%;
  }

  .hero p {
    line-height: 2rem;
    margin-bottom: 1rem;
  }

  .go-up {
    margin-top: 2rem;
  }

  .hero span.upper {
    font-size: 1.3rem;
    padding-left: 0;
    margin-bottom: 1rem;
    line-height: 1rem;
  }

  h1 {
    font-size: 2.4rem;
    margin-bottom: 0.7rem;
    line-height: 2.5rem;
  }

  .hero p {
    line-height: 1.8rem;
    margin-bottom: 0;
    font-size: 1.1rem;
    padding: 0;
  }

  form {
    padding: 2rem;
  }

  .pt-6 {
    padding-top: 2rem;
  }

  .pl-6 {
    padding-left: 0;
  }

  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .hero-content {
    padding: 2rem 1rem 3rem 1rem;
    background-color: #ffffff;
    margin-top: 0px;
  }

  .mt--8 {
    margin-top: 1rem;
  }

  .atmo-img {
    position: relative;
    left: 0rem;
    z-index: 100;
  }

  .form-back {
    margin-top: 0rem;
    padding: 3rem 2rem;
  }

}

@media (max-width: 575.98px) {

  form {
    margin-top: 0em;
    padding: 1.5rem 1rem;
  }

  footer {
    padding: 0 0 2rem;
  }

  footer h5 {
    font-size: 1.24rem;
    padding-top: 2rem;
  }

  footer h5 a {
    padding-top: 1rem;
    display: block;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .go-up {
    margin-top: 1rem;
  }

  .btn-lg {
    padding: 0.72rem 0.9rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 0;
  }

  .pl-6 {
    padding-left: 0;
  }

  .pt-6 {
    padding-top: 1rem;
  }

  .hero span.upper {
    font-size: 1.3rem;
    padding-left: 0;
    margin-bottom: 1rem;
    line-height: 1rem;
  }

  h1 {
    font-size: 2.4rem;
    margin-bottom: 0.7rem;
    line-height: 2.5rem;
  }

  .hero p {
    line-height: 1.8rem;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }

  .hero-content {
    padding: 0rem 1rem 3rem 1rem;
    background-color: #ffffff;
    margin-top: 0px;
  }

  .mt--8 {
    margin-top: -0.56rem;
  }

  .mb-8 {
    margin-bottom: 3rem;
  }

  .atmo-img {
    position: relative;
    left: 0rem;
    z-index: 100;
  }

  .form-back {
    margin-top: 0rem;
    padding: 3rem 2rem;
  }

}
